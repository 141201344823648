import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    link: {
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
  });
