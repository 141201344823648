import { graphql, StaticQuery } from "gatsby";
import React from "react";

export type WhenCareersAvailableProps = {};

class WhenCareersAvailable extends React.PureComponent<
  WhenCareersAvailableProps
> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query WhenCareersAvailableQuery {
            openPositions: allMarkdownRemark(
              filter: { fields: { slug: { regex: "/^/open-positions//" } } }
            ) {
              totalCount
            }
          }
        `}
        render={this.renderChildren}
      />
    );
  }

  private renderChildren = (queryProps: {
    openPositions: { totalCount: number };
  }) => {
    return queryProps.openPositions.totalCount > 0 ? this.props.children : null;
  };
}

export default WhenCareersAvailable;
