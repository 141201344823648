import React from "react";

export type WithLocaleIcons = {
  icons: { [locale: string]: SvgComponent | undefined };
};

export const withLocaleIconsBase = (locales: string[]) => <
  P extends WithLocaleIcons
>(
  Component: React.ComponentType<P>,
) => {
  type Props = Omit<P, keyof WithLocaleIcons>;
  type State = { icons?: WithLocaleIcons["icons"] };
  return class WithLocaleIconsHOC extends React.PureComponent<Props, State> {
    public state: State = {
      icons: {},
    };

    public componentDidMount() {
      void this.fetchIcons();
    }

    public render() {
      return <Component {...(this.props as P)} icons={this.state.icons} />;
    }

    private fetchIcons = async () => {
      const icons = (await Promise.all(
        locales.map((locale) =>
          import(`../../assets/icons/flags/${locale.toLocaleLowerCase()}.svg`),
        ),
      )) as { default: SvgComponent }[];

      const iconsMap = locales.reduce(
        (res: WithLocaleIcons["icons"], locale, index) => {
          res[locale.toLowerCase()] = icons[index].default;
          return res;
        },
        {},
      );

      this.setState({ icons: iconsMap });
    };
  };
};
