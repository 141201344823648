import { Theme } from "@material-ui/core";
import { createStylesWithProps } from "@src/utils/styles";

import { OwnLocaleButtonProps } from "./LocaleButton";

const createStyles = createStylesWithProps<OwnLocaleButtonProps>();

export const styles = (theme: Theme) =>
  createStyles({
    button: {
      paddingRight: 0,
    },
    localeIcon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(1),
    },
    localeText: {
      textTransform: "none",
    },
    expandIcon: {
      marginLeft: theme.spacing(1),
    },
  });
