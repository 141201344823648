import React from "react";

import TwitterSvg from "@src/assets/icons/Twitter.svg";
import { TWITTER } from "@src/utils/content/links";

import SocialLink, { SocialLinkProps } from "./SocialLink";

export const TwitterLink: React.SFC<SocialLinkProps> = (props) => (
  <SocialLink href={TWITTER} {...props}>
    <TwitterSvg />
  </SocialLink>
);
