import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    link: {
      ...theme.overrides!.MuiLink!.root,
    },
    // Please keep this so that $active from theme works
    active: {},
  });
