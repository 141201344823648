import {
  AppBar,
  Container,
  Toolbar,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";

import TrucknetLogo from "@src/components/TrucknetLogo";

import { styles } from "./Header.styles";
import Navigation from "./Navigation";

export type HeaderProps = WithStyles<typeof styles> & {
  className?: string;
};

class Header extends React.PureComponent<HeaderProps> {
  public render() {
    const { classes, className } = this.props;
    return (
      <AppBar
        className={cn(classes.appBar, className)}
        position="static"
        color="inherit">
        <Container>
          <Toolbar className={classes.toolbar} disableGutters>
            <TrucknetLogo className={classes.logo} />
            <Navigation />
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
