import {
  Dialog,
  Divider,
  IconButton,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { T } from "react-targem";

import MenuSvg from "@src/assets/icons/Menu.svg";
import { SignInButton, SignUpButton } from "@src/components/CommonButtons";
import NavLink from "@src/components/Header/Navigation/NavLink";
import LocaleSwitcher from "@src/components/LocaleSwitcher";
import TrucknetLogo from "@src/components/TrucknetLogo";
import WhenCareersAvailable from "@src/components/WhenCareersAvailable";

import { styles } from "./MobileNavigation.styles";
import ResourcesNavLinks from "./ResourcesNavLinks";

export type MobileNavigationProps = WithStyles<typeof styles> & {};

type MobileNavigationState = {
  open: boolean;
};

class MobileNavigation extends React.PureComponent<
  MobileNavigationProps,
  MobileNavigationState
> {
  public state = { open: false };

  public render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <nav className={classes.nav}>
        <IconButton
          className={classes.iconButton}
          aria-label="Open menu"
          onClick={this.openDialog}>
          <MenuSvg className={classes.menuIcon} />
        </IconButton>

        <Dialog
          className={classes.dialog}
          classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
          open={open}
          onClose={this.closeDialog}>
          <div className={classes.content} role="presentation">
            <div className={classes.container}>
              <div className={classes.header}>
                <TrucknetLogo small />
                <Close onClick={this.closeDialog} />
              </div>

              <div className={classes.links}>
                <NavLink to="/about">
                  <T message="About Us" />
                </NavLink>
                <ResourcesNavLinks />
                <WhenCareersAvailable>
                  <NavLink to="/careers">
                    <T message="Careers" />
                  </NavLink>
                </WhenCareersAvailable>
                <NavLink to="/contact">
                  <T message="Contact" />
                </NavLink>
                <NavLink to="/ti-sticker">
                  <T message="TI-Sticker" />
                </NavLink>
              </div>

              <LocaleSwitcher
                className={classes.localeSwitcher}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              />
            </div>

            <Divider className={classes.divider} />

            <div className={classes.container}>
              <div className={classes.buttons}>
                <SignInButton />
                <SignUpButton variant="text" color="secondary" />
              </div>
            </div>
          </div>
        </Dialog>
      </nav>
    );
  }

  private toggleDialog = (open: boolean) => this.setState({ open });
  private openDialog = () => this.toggleDialog(true);
  private closeDialog = () => this.toggleDialog(false);
}

export default withStyles(styles)(MobileNavigation);
