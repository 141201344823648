import React from "react";
import ReactHelmet from "react-helmet";

import mockupsPng from "@src/assets/images/mockups.png";
import { IS_DEV } from "@src/config/env";
import { LocaleCode } from "@src/config/locales";

export type HeadProps = {
  initialLocale?: LocaleCode;
  siteUrl: string;
};

export class Head extends React.PureComponent<HeadProps> {
  public render() {
    const { initialLocale, siteUrl } = this.props;

    return (
      <ReactHelmet>
        <html lang={initialLocale} />

        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={initialLocale} />
        <meta property="og:site_name" content="Trucknet" />
        <meta property="og:image" content={`${siteUrl}${mockupsPng}`} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary" />
        <meta name="robots" content={IS_DEV ? "noindex" : "index"} />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-768831641"
        />
        <script>
          {`
          window.dataLayer = []; function gtag(){window.dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'AW-768831641');
        `}
        </script>
      </ReactHelmet>
    );
  }
}

export default Head;
