import React from "react";
import { T } from "react-targem";

import CombinedNavLinks from "@src/components/Header/Navigation/DesktopNavigation/CombinedNavLinks";
import NavLinkItem from "@src/components/Header/Navigation/NavLinkItem";
import WhenBlogAvailable from "@src/components/WhenBlogAvailable";

export type Props = {
  className?: string;
};

class ResourcesNavMenu extends React.PureComponent<Props> {
  public render() {
    return (
      <CombinedNavLinks
        title={<T message="Resources" />}
        className={this.props.className}>
        <WhenBlogAvailable>
          <NavLinkItem to="/blog">
            <T message="Blog" />
          </NavLinkItem>
        </WhenBlogAvailable>
        <NavLinkItem to="/press">
          <T message="Press" />
        </NavLinkItem>
        <NavLinkItem to="/photos">
          <T message="Photos" />
        </NavLinkItem>
        <NavLinkItem to="/videos">
          <T message="Videos" />
        </NavLinkItem>
      </CombinedNavLinks>
    );
  }
}

export default ResourcesNavMenu;
