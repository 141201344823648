import React from "react";

import { WithPageData, withPageData } from "@src/contexts/PageDataContext";

export type WhenBlogAvailableProps = WithPageData & {};

class WhenBlogAvailable extends React.PureComponent<WhenBlogAvailableProps> {
  public render() {
    return this.props.blogPostsCount > 0 ? this.props.children : null;
  }
}

export default withPageData(WhenBlogAvailable);
