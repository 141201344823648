import React from "react";
import { Utils, withLocale, WithLocale } from "react-targem";

import Link, { LinkProps } from "@src/components/Link";
import { APP_SUPPORTED_LOCALES } from "@src/config/locales";
import { getAppLink } from "@src/utils/content/links";

export type AppLinkProps = Omit<LinkProps, "external"> & {};

export class AppLink extends React.PureComponent<WithLocale & AppLinkProps> {
  public render() {
    const { locale, to, direction, t, tn, tf, tnf, ...rest } = this.props;
    return (
      <Link
        to={`${getAppLink(locale)}${to}/${this.getLocalePath()}`}
        external
        {...rest}
      />
    );
  }

  private getLocalePath(): string {
    return (
      Utils.findLocale(APP_SUPPORTED_LOCALES, this.props.locale) ||
      APP_SUPPORTED_LOCALES[0]
    );
  }
}

export default withLocale(AppLink);
