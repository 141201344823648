import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    dropdown: {
      position: "relative",
      "&:hover $menu": {
        visibility: "visible",
      },
      "&:hover $expandIcon": {
        transform: "rotate(180deg)",
      },
    },
    button: {
      display: "flex",
    },
    menu: {
      minWidth: "140px",
      padding: theme.spacing(1.5, 0),
      marginTop: theme.spacing(1.75),
      position: "absolute",
      visibility: "hidden",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    title: {
      color:
        theme.trucknet.headerStyle === "light"
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
    expandIcon: {
      marginLeft: theme.spacing(1),
      transition: "transform 250ms ease-in-out",
    },
  });
