import {
  Typography,
  TypographyProps,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import Link, { LinkProps } from "@src/components/Link";

import { styles } from "./NavLink.styles";

export interface NavLinkProps extends LinkProps, WithStyles<typeof styles> {
  className?: string;
  typographyVariant?: TypographyProps["variant"];
}

class NavLink extends React.PureComponent<NavLinkProps> {
  public render() {
    const {
      children,
      classes,
      className,
      typographyVariant,
      ...rest
    } = this.props;

    return (
      <Link {...rest} className={classes.navLink}>
        <Typography
          variant={typographyVariant || "subtitle1"}
          className={className}>
          {children}
        </Typography>
      </Link>
    );
  }
}

export default withStyles(styles)(NavLink);
