import React from "react";

import LinkedInSvg from "@src/assets/icons/LinkedIn.svg";
import { LINKED_IN } from "@src/utils/content/links";

import SocialLink, { SocialLinkProps } from "./SocialLink";

export const LinkedInLink: React.SFC<SocialLinkProps> = (props) => (
  <SocialLink href={LINKED_IN} {...props}>
    <LinkedInSvg />
  </SocialLink>
);
