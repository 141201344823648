import {
  Collapse,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import ExpandMoreIcon from "@src/components/ExpandMoreIcon";
import NavLink from "@src/components/Header/Navigation/NavLink";
import WhenBlogAvailable from "@src/components/WhenBlogAvailable";

import { styles } from "./ResourcesNavLinks.styles";

export type Props = WithStyles<typeof styles> & {
  className?: string;
};

type State = {
  isOpen: boolean;
};

class ResoursesNavLinks extends React.PureComponent<Props, State> {
  public state: State = {
    isOpen: false,
  };

  public render() {
    const { isOpen } = this.state;
    const { classes, className } = this.props;

    return (
      <div className={className}>
        <div
          role="button"
          className={classes.button}
          onClick={this.handleToggleLinks}>
          <Typography variant="subtitle1">
            <T message="Resources" />
          </Typography>
          <ExpandMoreIcon open={isOpen} className={classes.expandIcon} />
        </div>
        <Collapse in={isOpen}>
          <WhenBlogAvailable>
            <NavLink
              to="/blog"
              typographyVariant="body2"
              className={classes.link}>
              <T message="Blog" />
            </NavLink>
          </WhenBlogAvailable>
          <NavLink
            to="/press"
            typographyVariant="body2"
            className={classes.link}>
            <T message="Press" />
          </NavLink>
          <NavLink
            to="/photos"
            typographyVariant="body2"
            className={classes.link}>
            <T message="Photos" />
          </NavLink>
          <NavLink
            to="/videos"
            typographyVariant="body2"
            className={classes.link}>
            <T message="Videos" />
          </NavLink>
        </Collapse>
      </div>
    );
  }

  private handleToggleLinks = () =>
    this.setState({ isOpen: !this.state.isOpen });
}

export default withStyles(styles)(ResoursesNavLinks);
