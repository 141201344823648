import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "block",
    },
    linksContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
    socialLinks: {
      paddingTop: theme.spacing(1.25),
      "& > :first-child": {
        marginRight: theme.spacing(3),
      },
    },
  });
