import { withStyles, WithStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import cn from "classnames";
import React from "react";

import { styles } from "./ExpandMoreIcon.styles";

export type ExpandMoreIconProps = {
  open: boolean;
  className?: string;
};

const ExpandMoreIcon = (
  props: ExpandMoreIconProps & WithStyles<typeof styles>,
) => <ExpandMore className={cn(props.classes.expandIcon, props.className)} />;

export default withStyles(styles)(ExpandMoreIcon);
