import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    switcher: {},
    popover: {
      minWidth: "280px",
    },
    ssrLinks: {
      display: "none",
    },
  });
