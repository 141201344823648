import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    link: {
      height: "96px",
      width: "96px",
    },
    logo: {
      height: "100%",
      width: "100%",
    },
  });
