import { Hidden } from "@material-ui/core";
import React from "react";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export type NavigationProps = {
  className?: string;
  mobile?: boolean;
};

class Navigation extends React.PureComponent<NavigationProps> {
  public render() {
    const { className, mobile } = this.props;
    return mobile ? (
      <Hidden mdUp>
        <Mobile className={className} />
      </Hidden>
    ) : (
      <Hidden className={className} smDown implementation="css">
        <Desktop />
      </Hidden>
    );
  }
}

export default Navigation;
