import { Menu, MenuProps, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { withLocale, WithLocale } from "react-targem";

import {
  withLocaleIcons,
  WithLocaleIcons,
} from "@src/components/withLocaleIcons";
import { LOCALES, LOCALES_MAP } from "@src/config/locales";

import LocaleButton from "./LocaleButton";
import LocaleItem from "./LocaleItem";
import { styles } from "./LocaleSwitcher.styles";

export type LocaleSwitcherProps = Omit<MenuProps, "open"> & {
  className?: string;
};

type LocaleSwitcherState = {
  anchorEl?: HTMLElement;
};

class LocaleSwitcher extends React.PureComponent<
  WithLocaleIcons &
    WithLocale &
    WithStyles<typeof styles> &
    LocaleSwitcherProps,
  LocaleSwitcherState
> {
  public state: LocaleSwitcherState = {};

  public render() {
    const {
      classes,
      className,
      locale,
      direction,
      icons,
      t,
      tn,
      tf,
      tnf,
      ...rest
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={cn(classes.switcher, className)}>
        <LocaleButton
          locale={LOCALES_MAP[locale]}
          icon={icons[locale]}
          open={open}
          onClick={this.handleMenu}
        />
        <div className={classes.ssrLinks}>
          {LOCALES.map((l) => (
            <LocaleItem key={l.code} activeLocale={locale} locale={l} ssr />
          ))}
        </div>
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          PopoverClasses={{ paper: classes.popover }}
          {...rest}>
          {LOCALES.map((l) => (
            <LocaleItem
              key={l.code}
              activeLocale={locale}
              locale={l}
              icon={icons[l.code]}
            />
          ))}
        </Menu>
      </div>
    );
  }

  private handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: undefined });
  };
}

export default withLocaleIcons(withLocale(withStyles(styles)(LocaleSwitcher)));
