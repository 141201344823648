import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      maxWidth: "600px",
      justifyContent: "space-between",
    },
    firstSocialLink: {
      marginRight: theme.spacing(3),
    },
  });
