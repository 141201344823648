import { createGenerateClassName, jssPreset } from "@material-ui/styles";
import { create, JssOptions, Plugin } from "jss";
import jssRtl from "jss-rtl";

export const getJss = (options?: Partial<JssOptions>) => {
  const jssRtlPlugin = jssRtl({
    enabled: true,
  }) as Plugin;
  return create({
    plugins: [...jssPreset().plugins, jssRtlPlugin],
    ...options,
  });
};

export const getJssClassName = () => {
  return createGenerateClassName();
};

export default {
  jss: getJss(),
  generateClassName: getJssClassName(),
};
