import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 0,
    },
    menuIcon: {
      "& path:last-of-type": {
        fill:
          theme.trucknet.headerStyle === "light"
            ? theme.palette.common.white
            : theme.palette.primary.main,
      },
    },
    nav: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
    },
    dialog: {},
    paper: {
      width: "100%",
      margin: theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
      maxWidth: "none",
    },
    scrollPaper: {
      alignItems: "flex-start",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    content: {},
    container: {
      padding: theme.spacing(2, 3),
    },
    links: {
      padding: theme.spacing(2.5, 0),
      "& h6": {
        padding: theme.spacing(2.5, 0),
      },
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    buttons: {
      "& > *": {
        textAlign: "center",
        width: "180px",
        minWidth: "180px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "& > *:first-child": {
        marginBottom: theme.spacing(2),
      },
    },
    localeSwitcher: {
      marginLeft: theme.spacing(-1),
    },
  });
