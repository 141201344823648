import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: "none",
      backgroundColor:
        theme.trucknet.headerStyle === "light"
          ? "rgba(0, 0, 0, 0.24)"
          : "transparent",
    },
    toolbar: {
      [theme.breakpoints.up("lg")]: {
        height: "100px",
      },
    },
    logo: {
      flexGrow: 0.7,
      paddingRight: theme.spacing(4),
      "& > svg > g > path:first-of-type": {
        fill:
          theme.trucknet.headerStyle === "light"
            ? theme.palette.common.white
            : theme.palette.primary.main,
      },
    },
  });
