import React from "react";
import { T, WithLocale, withLocale } from "react-targem";

import ButtonLink, { ButtonLinkProps } from "@src/components/ButtonLink";
import { getAppLink } from "@src/utils/content/links";

export type SignInButtonProps = Omit<ButtonLinkProps, "classes" | "to">;

class SignInButtonBase extends React.PureComponent<
  WithLocale & SignInButtonProps
> {
  public render() {
    const { children, locale, direction, t, tn, tf, tnf, ...rest } = this.props;

    return (
      <ButtonLink
        to={`${getAppLink(locale)}/login`}
        variant="contained"
        color="secondary"
        external
        {...rest}>
        {children || <T message="Sign In" />}
      </ButtonLink>
    );
  }
}

export const SignInButton = withLocale(SignInButtonBase);
