import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    listItemIcon: {
      minWidth: "44px",
      padding: "2px",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    listItemText: {
      color: theme.palette.primary.main,
    },
  });
