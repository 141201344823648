import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { StylesProvider, StylesProviderProps } from "@material-ui/styles";
import React from "react";
import { WithLocale, withLocale } from "react-targem";

import jss from "@src/config/jss";
import { createTheme } from "@src/config/theme";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";

type Props = WithLocale &
  WithPageData & {
    stylesProviderProps?: Partial<StylesProviderProps>;
  };

export class MuiLocaleProvider extends React.PureComponent<Props> {
  public render() {
    const { direction, headerStyle, stylesProviderProps } = this.props;
    return (
      <MuiThemeProvider theme={createTheme({ direction, headerStyle })}>
        <StylesProvider {...jss} {...stylesProviderProps}>
          <React.Fragment>
            <CssBaseline />
            {this.props.children}
          </React.Fragment>
        </StylesProvider>
      </MuiThemeProvider>
    );
  }
}

export default withPageData(withLocale(MuiLocaleProvider));
