export const COLORS = {
  white: "#fff",
  primaryMain: "#1f3239",
  secondaryMain: "#2cce62",
  secondaryLight: "#e9faef",
  textPrimary: "#29464d",
  firstGradient: "#2cceb3",
  textSecondary: "#808080",
  blue: "#2979FF",
};
