import { Paper, Typography, withStyles, WithStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import cn from "classnames";
import React from "react";

import { styles } from "./CombinedNavLinks.styles";

export type Props = WithStyles<typeof styles> & {
  title: React.ReactNode;
  className?: string;
};

class CombinedNavLinks extends React.PureComponent<Props> {
  public render() {
    const { className, title, children, classes } = this.props;

    return (
      <div className={cn(classes.dropdown, className)}>
        <div className={classes.button}>
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
          <ExpandMore className={classes.expandIcon} />
        </div>
        <Paper className={classes.menu} elevation={3}>
          {children}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(CombinedNavLinks);
