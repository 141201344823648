import { Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import { styles } from "./Column.styles";

export type ColumnProps = WithStyles<typeof styles> & {
  className?: string;
  title: React.ReactNode;
  simple?: boolean;
};

class Column extends React.PureComponent<ColumnProps> {
  public render() {
    const { classes, className, children, title, simple } = this.props;
    return (
      <div className={cn(classes.column, className)}>
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
        {simple
          ? children
          : React.Children.map(children, (child) => (
              <Typography className={classes.link} variant="body1">
                {child}
              </Typography>
            ))}
      </div>
    );
  }
}

export default withStyles(styles)(Column);
