import { StyleRules } from "@material-ui/styles";

// Factory to create type-safe createStyles function that
// has access to particular type of Props and infers
// ClassKey type from the passed styles
export const createStylesWithProps = <Props extends {}>() => <
  ClassKey extends string
>(
  styles: StyleRules<Props, ClassKey>,
): StyleRules<Props, ClassKey> => styles;

export const calcVideoHeight = (width: string) => `calc(${width} / 16 * 9)`;
