import { ListItem, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import NavLink from "@src/components/Header/Navigation/NavLink";

import { styles } from "./NavLinkItem.styles";

export type Props = WithStyles<typeof styles> & {
  to: string;
  children: React.ReactNode;
};

class NavLinkItem extends React.PureComponent<Props> {
  public render() {
    const { to, children, classes } = this.props;
    return (
      <NavLink to={to}>
        <ListItem button className={classes.listItem}>
          {children}
        </ListItem>
      </NavLink>
    );
  }
}

export default withStyles(styles)(NavLinkItem);
