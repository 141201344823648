import React from "react";
import { TranslationsMap } from "react-targem";

import { DEFAULT_LOCALE, LocaleCode } from "@src/config/locales";
import { HeaderStyle, PageProps } from "@src/types";

import { ContextHOC, createContextHOC } from "./createContextHOC";

export type WithPageData<PP extends PageProps = PageProps> = {
  pageData: PP["data"];
  headerStyle: HeaderStyle;
  path: string;
  initialLocale: LocaleCode;
  translations: TranslationsMap;
  blogPostsCount: number;
};

const { Provider, Consumer } = React.createContext<WithPageData>({
  pageData: {},
  headerStyle: "dark",
  path: "/",
  initialLocale: DEFAULT_LOCALE.code,
  translations: {},
  blogPostsCount: 1,
});

export { Provider as PageDataProvider };

export const withPageData = <PP extends PageProps, P extends WithPageData<PP>>(
  Component: React.ComponentType<P>,
) => (createContextHOC(Consumer) as ContextHOC<P>)(Component);
