export type CountryConfig = {
  name: string;
  region: string;
  iso: string;
  locale: string;
  language: string;
};

export type CountriesMap = {
  [isoCode: string]: CountryConfig;
};

export const COUNTRIES_MAP: CountriesMap = {
  FR: {
    name: "France",
    region: "Europe",
    iso: "FR",
    locale: "fr",
    language: "Français",
  },
  RO: {
    name: "Romania",
    region: "Europe",
    iso: "RO",
    locale: "ro",
    language: "Română",
  },
  GB: {
    name: "United Kingdom",
    region: "Europe",
    iso: "GB",
    locale: "en",
    language: "English",
  },
  DE: {
    name: "Germany",
    region: "Europe",
    iso: "DE",
    locale: "de",
    language: "Deutsche",
  },
  BE: {
    name: "Belgium",
    region: "Europe",
    iso: "BE",
    locale: "fr",
    language: "Français",
  },
  AT: {
    name: "Austria",
    region: "Europe",
    iso: "AT",
    locale: "de",
    language: "Deutsche",
  },
  BG: {
    name: "Bulgaria",
    region: "Europe",
    iso: "BG",
    locale: "bg",
    language: "български",
  },
  HR: {
    name: "Croatia",
    region: "Europe",
    iso: "HR",
    locale: "hr",
    language: "Hrvatski",
  },
  CY: {
    name: "Cyprus",
    region: "Europe",
    iso: "CY",
    locale: "el",
    language: "Ελληνικά",
  },
  CZ: {
    name: "Czech Republic",
    region: "Europe",
    iso: "CZ",
    locale: "cs",
    language: "čeština",
  },
  DK: {
    name: "Denmark",
    region: "Europe",
    iso: "DK",
    locale: "da",
    language: "dansk",
  },
  EE: {
    name: "Estonia",
    region: "Europe",
    iso: "EE",
    locale: "et",
    language: "Eesti keel",
  },
  FI: {
    name: "Finland",
    region: "Europe",
    iso: "FI",
    locale: "fi",
    language: "Soome keel",
  },
  GR: {
    name: "Greece",
    region: "Europe",
    iso: "GR",
    locale: "el",
    language: "Ελληνικά",
  },
  HU: {
    name: "Hungary",
    region: "Europe",
    iso: "HU",
    locale: "hu",
    language: "Magyar",
  },
  IE: {
    name: "Ireland",
    region: "Europe",
    iso: "IE",
    locale: "en",
    language: "English",
  },
  IL: {
    name: "Israel",
    region: "Israel",
    iso: "IL",
    locale: "he",
    language: "עברית (Hebrew)",
  },
  IT: {
    name: "Italy",
    region: "Europe",
    iso: "IT",
    locale: "it",
    language: "Italiano",
  },
  LV: {
    name: "Latvia",
    region: "Europe",
    iso: "LV",
    locale: "lv",
    language: "Latviešu valoda",
  },
  LT: {
    name: "Lithuania",
    region: "Europe",
    iso: "LT",
    locale: "lt",
    language: "Lietuvių",
  },
  LU: {
    name: "Luxembourg",
    region: "Europe",
    iso: "LU",
    locale: "fr",
    language: "Français",
  },
  MT: {
    name: "Malta",
    region: "Europe",
    iso: "MT",
    locale: "mt",
    language: "Malti",
  },
  MD: {
    name: "Moldova",
    region: "Europe",
    iso: "MD",
    locale: "ro",
    language: "Română",
  },
  NL: {
    name: "Netherlands",
    region: "Europe",
    iso: "NL",
    locale: "nl",
    language: "Dutch",
  },
  PL: {
    name: "Poland",
    region: "Europe",
    iso: "PL",
    locale: "pl",
    language: "Polskie",
  },
  PT: {
    name: "Portugal",
    region: "Europe",
    iso: "PT",
    locale: "pt",
    language: "Português",
  },
  SK: {
    name: "Slovakia",
    region: "Europe",
    iso: "SK",
    locale: "sk",
    language: "slovenský",
  },
  SI: {
    name: "Slovenia",
    region: "Europe",
    iso: "SI",
    locale: "sl",
    language: "Slovenščina",
  },
  ES: {
    name: "Spain",
    region: "Europe",
    iso: "ES",
    locale: "es",
    language: "Español",
  },
  SE: {
    name: "Sweden",
    region: "Europe",
    iso: "SE",
    locale: "sv",
    language: "svenska",
  },
};

export const COUNTRIES_ISO = Object.keys(COUNTRIES_MAP);
