import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    small: {},
    link: {
      height: "24px",
      "&$small": {
        height: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "16px",
        width: "104px",
      },
    },
    logo: {
      height: "24px",
      width: "158px",
      "$small &": {
        height: "16px",
        width: "104px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "16px",
        width: "104px",
      },
    },
  });
