import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    navLink: {
      "&:hover": {
        color: "inherit",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
        "&:hover": {
          "&:after": {
            display: "none",
          },
        },
      },
    },
  });
