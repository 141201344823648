import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    normal: {
      minWidth: "180px",
    },
    small: {
      minWidth: "124px",
    },
  });
