import { WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import { styles } from "./SocialLink.styles";

export type SocialLinkOwnProps = React.HTMLProps<HTMLAnchorElement> & {
  className?: string;
  href: string;
};

export type SocialLinkProps = Omit<SocialLinkOwnProps, "href">;

export class SocialLink extends React.PureComponent<
  WithStyles<typeof styles> & SocialLinkOwnProps
> {
  public render() {
    const { className, classes, href, ...rest } = this.props;
    return (
      // tslint:disable-next-line: react-a11y-anchors
      <a
        className={cn(classes.link, className)}
        href={href}
        target="_blank"
        rel="noreferrer"
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(SocialLink);
