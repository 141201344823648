import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Link, navigate } from "gatsby";
import React from "react";

import { Locale, LocaleCode } from "@src/config/locales";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { changeLocaleInPath } from "@src/utils/locale";

import { styles } from "./LocaleItem.styles";

export type LocaleItemProps = WithPageData &
  WithStyles<typeof styles> & {
    activeLocale: LocaleCode;
    locale: Locale;
    icon?: SvgComponent;
    ssr?: boolean;
  };

class LocaleItem extends React.PureComponent<LocaleItemProps> {
  public render() {
    const { activeLocale, classes, locale, icon: Icon, ssr } = this.props;

    if (ssr) {
      return <Link to={this.getLink()}>{locale.localTitle}</Link>;
    }

    return (
      <Link
        className={classes.link}
        to={this.getLink()}
        onClick={this.handleClick}>
        <MenuItem selected={activeLocale === locale.code}>
          <ListItemIcon className={classes.listItemIcon}>
            {Icon ? <Icon className={classes.icon} /> : <span />}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle1">{locale.localTitle}</Typography>
          </ListItemText>
        </MenuItem>
      </Link>
    );
  }

  private getLink = (): string => {
    const path =
      typeof window !== "undefined"
        ? `${window.location.pathname}${window.location.search}`
        : this.props.path;
    return changeLocaleInPath(path, this.props.locale.code);
  };

  private handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    void navigate(this.getLink());
  };
}

export default withPageData(withStyles(styles)(LocaleItem));
