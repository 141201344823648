import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    column: {
      "&:not(:last-child)": {
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      fontWeight: 500,
      marginBottom: theme.spacing(3),
    },
    link: {
      display: "block",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  });
