import {
  Button,
  ButtonProps,
  Hidden,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import ExpandMoreIcon from "@src/components/ExpandMoreIcon";
import { Locale } from "@src/config/locales";

import { styles } from "./LocaleButton.styles";

export type OwnLocaleButtonProps = {
  open: boolean;
  locale: Locale;
  icon?: SvgComponent;
};

export type LocaleButtonProps = ButtonProps & OwnLocaleButtonProps;

class LocaleButton extends React.PureComponent<
  WithStyles<typeof styles> & LocaleButtonProps
> {
  public render() {
    const { classes, locale, open, icon: Icon, ...rest } = this.props;

    return (
      <Button
        className={classes.button}
        aria-owns={open ? "menu-appbar" : undefined}
        aria-haspopup="true"
        {...rest}>
        {Icon ? <Icon className={classes.localeIcon} /> : null}
        <Typography className={classes.localeText} variant="subtitle1">
          <Hidden lgUp implementation="css">
            {locale.localTitle}
          </Hidden>
          <Hidden mdDown>{locale.shortTitle}</Hidden>
        </Typography>
        <Hidden lgUp>
          <ExpandMoreIcon className={classes.expandIcon} open={open} />
        </Hidden>
      </Button>
    );
  }
}

export default withStyles(styles)(LocaleButton);
