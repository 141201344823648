import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import Footer from "@src/components/Footer";
import Header from "@src/components/Header";
import { ContainerProps } from "@src/types";

import { styles } from "./MainLayout.styles";

export type HeaderComponentProps = { header: JSX.Element };
export type HeaderComponentType = React.ComponentType<HeaderComponentProps>;

type Props = WithStyles<typeof styles> & {
  headerComponent?: HeaderComponentType;
};

class MainLayout extends React.PureComponent<Props> {
  public render() {
    const { children, classes, headerComponent: HeaderComponent } = this.props;
    const header = <Header />;
    return (
      <div className={classes.pageContainer}>
        {HeaderComponent ? <HeaderComponent header={header} /> : header}
        <main className={classes.main}>{children}</main>
        <Footer />
      </div>
    );
  }
}

const MainLayoutStyled = withStyles(styles)(MainLayout);

export type MainLayoutProps = ContainerProps<typeof MainLayout>;

export default MainLayoutStyled;
