import { Button, ButtonProps, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import LocalizedLink, {
  LocalizedLinkProps,
} from "@src/components/LocalizedLink";

import { styles } from "./ButtonLink.styles";

export type ButtonLinkProps = Omit<ButtonProps, "ref"> &
  LocalizedLinkProps & {
    external?: boolean;
    small?: boolean;
  };

const LinkComponent = React.forwardRef<HTMLAnchorElement, LocalizedLinkProps>(
  ({ innerRef, ...props }, ref) => (
    <LocalizedLink innerRef={ref as Function} {...props} />
  ),
);

class ButtonLink extends React.PureComponent<
  WithStyles<typeof styles> & ButtonLinkProps
> {
  public render() {
    const {
      className,
      classes,
      innerRef,
      external,
      to,
      href,
      small,
      ...rest
    } = this.props;
    const props: Partial<ButtonLinkProps> = {};

    if (external) {
      // @ts-ignore https://github.com/mui-org/material-ui/issues/15827#issuecomment-510422878
      props.component = "a";
      props.href = to;
      props.target = "_blank";
    } else {
      // @ts-ignore
      props.component = LinkComponent;
      props.to = to;
    }

    return (
      <Button
        className={cn(
          { [classes.small]: small, [classes.normal]: !small },
          className,
        )}
        {...props}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(ButtonLink);
