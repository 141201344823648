// Keep this as .js with a separate type definitions to allow
// importing this in Gatsby's js configs

const LOCALES = [
  {
    code: "en",
    englishTitle: "English",
    localTitle: "English",
    shortTitle: "En",
  },
  {
    code: "fr",
    englishTitle: "French",
    localTitle: "Français",
    shortTitle: "Fr",
  },
  {
    code: "ro",
    englishTitle: "Romanian",
    localTitle: "Română",
    shortTitle: "Ro",
  },
  {
    code: "es",
    englishTitle: "Spanish",
    localTitle: "Español",
    shortTitle: "Es",
  },
  {
    code: "it",
    englishTitle: "Italian",
    localTitle: "Italiano",
    shortTitle: "It",
  },
  {
    code: "he",
    englishTitle: "Hebrew",
    localTitle: "עברית",
    direction: "rtl",
    shortTitle: "He",
  },
];

const LOCALE_CODES = LOCALES.map((l) => l.code);

const LOCALES_MAP = LOCALES.reduce((res, locale) => {
  res[locale.code] = locale;
  return res;
}, {});

const DEFAULT_LOCALE = LOCALES[0];

const APP_SUPPORTED_LOCALES = ["en", "fr"];

const LOCALE_TO_DATE_FNS = {
  en: "en-GB",
};

module.exports = {
  LOCALES,
  LOCALES_MAP,
  LOCALE_CODES,
  DEFAULT_LOCALE,
  APP_SUPPORTED_LOCALES,
  LOCALE_TO_DATE_FNS,
};
