import {
  Container,
  Divider,
  Hidden,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";

import IsoLogo from "@src/components/IsoLogo";
import TrucknetLogo from "@src/components/TrucknetLogo";

import { styles } from "./Footer.styles";
import Navigation from "./Navigation";
import RightsAndTerms from "./RightsAndTerms";

export type FooterProps = WithStyles<typeof styles> & {
  className?: string;
};

class Footer extends React.PureComponent<FooterProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <footer className={cn(classes.footer, className)}>
        <Container className={classes.navigationWrapper}>
          <div className={classes.logosContainer}>
            <TrucknetLogo className={classes.logo} small />
            <Hidden smDown>
              <IsoLogo />
            </Hidden>
          </div>
          <Navigation className={classes.navigationDesktop} />
        </Container>

        <Navigation mobile />

        <Divider className={classes.divider} />

        <Container>
          <RightsAndTerms />
        </Container>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
