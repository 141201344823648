import React from "react";

import { LocaleProvider } from "@src/contexts/LocaleProvider";

import MuiLocaleProvider from "./MuiLocaleProvider";

export type RootLayoutProps = {};

class RootLayout extends React.PureComponent<RootLayoutProps> {
  public render() {
    return (
      <LocaleProvider>
        <MuiLocaleProvider>{this.props.children}</MuiLocaleProvider>
      </LocaleProvider>
    );
  }
}

export default RootLayout;
