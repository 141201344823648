import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    nav: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    button: {
      marginLeft: theme.spacing(1.5),
      minWidth: "auto",
    },
    localeSwitcher: {
      marginLeft: theme.spacing(2),
      "& h6, & svg:last-of-type": {
        color:
          theme.trucknet.headerStyle === "light"
            ? theme.palette.common.white
            : theme.palette.primary.main,
      },
    },
    link: {
      cursor: "pointer",
      padding: theme.spacing(1.75, 0),
      margin: theme.spacing(0, 1.5),
      position: "relative",
      "&:hover": {
        color:
          theme.trucknet.headerStyle === "light"
            ? theme.palette.common.white
            : theme.palette.primary.main,
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "4px",
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(94deg, ${theme.palette.trucknet.firstGradient}, ${theme.palette.secondary.main})`,
        },
      },
      color:
        theme.trucknet.headerStyle === "light"
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
  });
