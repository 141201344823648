import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {},
    panel: {
      borderRadius: 0,
      boxShadow: "none",
    },
    panelSummary: {
      padding: 0,
      minHeight: "0 !important",
    },
    panelContent: {
      marginTop: `${theme.spacing(2)}px !important`,
      marginBottom: `${theme.spacing(1)}px !important`,
    },
    panelDetails: {
      display: "block",
      padding: 0,
    },
    title: {
      fontWeight: 500,
    },
    expandIcon: {
      fill: theme.palette.primary.main,
    },
    link: {
      marginBottom: theme.spacing(1),
      "&:first-child": {
        marginTop: theme.spacing(1),
      },
      "&:last-child": {
        marginBottom: theme.spacing(2),
      },
    },
  });
