import React from "react";
import { T } from "react-targem";

import ButtonLink, { ButtonLinkProps } from "@src/components/ButtonLink";

export type ContactUsButtonProps = Omit<ButtonLinkProps, "classes" | "to">;

export class ContactUsButton extends React.PureComponent<ContactUsButtonProps> {
  public render() {
    return (
      <ButtonLink
        to="/contact"
        variant="text"
        color="secondary"
        {...this.props}>
        <T message="Contact Us" />
      </ButtonLink>
    );
  }
}
