import { Theme } from "@material-ui/core";
import { createStylesWithProps } from "@src/utils/styles";

import { ExpandMoreIconProps } from "./ExpandMoreIcon";

const createStyles = createStylesWithProps<ExpandMoreIconProps>();

export const styles = (theme: Theme) =>
  createStyles({
    expandIcon: {
      width: "24px",
      height: "24px",
      transition: "transform 250ms ease-in-out",
      transform: (props) => (props.open ? "rotate(180deg)" : "none"),
      color:
        theme.trucknet.headerStyle === "light"
          ? theme.palette.common.white
          : theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        color: theme.palette.primary.main,
      },
    },
  });
