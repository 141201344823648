import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    button: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover *": {
        color: theme.palette.text.secondary,
      },
    },
    link: {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2.5, 0),
    },
    expandIcon: {
      marginLeft: theme.spacing(1),
    },
  });
