import React from "react";
import ReactHelmet from "react-helmet";

import { DEFAULT_LOCALE } from "@src/config/locales";
import { isSupportedLocaleInPath } from "@src/utils/locale";

export type CanonicalLinkProps = {
  host: string;
  path: string;
};

export class CanonicalLink extends React.PureComponent<CanonicalLinkProps> {
  public render() {
    const { host, path } = this.props;
    const canonicalHref = isSupportedLocaleInPath(path)
      ? `${host}${path}`
      : `${host}/${DEFAULT_LOCALE.code}${path}`;

    return (
      <ReactHelmet>
        <link rel="canonical" href={canonicalHref} />
      </ReactHelmet>
    );
  }
}

export default CanonicalLink;
