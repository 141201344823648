import { Hidden } from "@material-ui/core";
import React from "react";

import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";

export type NavigationProps = {};

class Navigation extends React.PureComponent<NavigationProps> {
  public render() {
    return (
      <React.Fragment>
        <DesktopNavigation />
        <Hidden lgUp>
          <MobileNavigation />
        </Hidden>
      </React.Fragment>
    );
  }
}

export default Navigation;
