import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import TrucknetLogoSvg from "@src/assets/icons/TrucknetLogo.svg";
import LocalizedLink from "@src/components/LocalizedLink";

import { styles } from "./TrucknetLogo.styles";

export type TrucknetLogoProps = WithStyles<typeof styles> & {
  className?: string;
  small?: boolean;
};

class TrucknetLogo extends React.PureComponent<TrucknetLogoProps> {
  public render() {
    const { classes, className, small } = this.props;
    return (
      <LocalizedLink
        to="/"
        className={cn(className, classes.link, { [classes.small]: small })}>
        <TrucknetLogoSvg className={classes.logo} />
      </LocalizedLink>
    );
  }
}

export default withStyles(styles)(TrucknetLogo);
