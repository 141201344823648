import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { EnterButton } from "@src/components/CommonButtons";
import NavLink from "@src/components/Header/Navigation/NavLink";
import LocaleSwitcher from "@src/components/LocaleSwitcher";
import WhenCareersAvailable from "@src/components/WhenCareersAvailable";

import { styles } from "./DesktopNavigation.styles";
import ResourcesNavMenu from "./ResourcesNavMenu";

export type DesktopNavigationProps = WithStyles<typeof styles> & {};

class DesktopNavigation extends React.PureComponent<DesktopNavigationProps> {
  public render() {
    const { classes } = this.props;

    return (
      <nav className={classes.nav}>
        <NavLink to="/about" className={classes.link}>
          <T message="About Us" />
        </NavLink>
        <ResourcesNavMenu className={classes.link} />
        <WhenCareersAvailable>
          <NavLink to="/careers" className={classes.link}>
            <T message="Careers" />
          </NavLink>
        </WhenCareersAvailable>
        <NavLink to="/contact" className={classes.link}>
          <T message="Contact" />
        </NavLink>
        <NavLink to="/ti-sticker" className={classes.link}>
          <T message="TI-Sticker" />
        </NavLink>
        <EnterButton small className={classes.button} />
        <LocaleSwitcher className={classes.localeSwitcher} />
      </nav>
    );
  }
}

export default withStyles(styles)(DesktopNavigation);
