import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import AppLink from "@src/components/AppLink";

import { styles } from "./RightsAndTerms.styles";

export type RightsAndTermsProps = WithStyles<typeof styles> & {
  className?: string;
};

class RightsAndTerms extends React.PureComponent<RightsAndTermsProps> {
  private currentYear = new Date().getFullYear();

  public render() {
    const { classes, className } = this.props;
    return (
      <div className={cn(classes.container, className)}>
        <Typography className={classes.rights} variant="body2">
          © {this.currentYear} Trucknet. <T message="All rights reserved" />.
        </Typography>

        <Typography className={classes.terms} variant="body2">
          <AppLink to="/terms-and-conditions">
            <T message="Terms and Conditions" />
          </AppLink>
          <AppLink to="/privacy-policy">
            <T message="Privacy Policy" />
          </AppLink>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(RightsAndTerms);
