import React from "react";
import ReactHelmet from "react-helmet";
import { withLocale, WithLocale } from "react-targem";

export type SeoHeadProps = WithLocale & {
  title: string;
  description: string;
  keywords: string[];
};

class SeoHead extends React.PureComponent<SeoHeadProps> {
  public render() {
    const { title, description, keywords, t } = this.props;

    return (
      <ReactHelmet>
        <title>{t(title)}</title>
        <meta name="description" content={t(description)} />
        <meta
          name="keywords"
          content={`trucknet, ${t("transportation")}, ${t(
            "optimization",
          )}, ${keywords.map((k) => t(k)).join(", ")}`}
        />
      </ReactHelmet>
    );
  }
}

export default withLocale(SeoHead);
