import React from "react";
import { TargemProvider, Utils } from "react-targem";

import {
  DEFAULT_LOCALE,
  LocaleDirection,
  LOCALE_CODES,
} from "@src/config/locales";
import { isSupportedLocaleInPath } from "@src/utils/locale";

import { WithPageData, withPageData } from "./PageDataContext";

type Props = WithPageData & {
  setBodyDir?(dir: LocaleDirection): void;
};

class LocaleProviderBase extends React.Component<Props> {
  public componentDidMount() {
    const { initialLocale, path } = this.props;
    if (!initialLocale && !isSupportedLocaleInPath(path)) {
      const defaultLocale = Utils.getBrowserLocale(
        LOCALE_CODES,
        DEFAULT_LOCALE.code,
      );
      window.location.replace(
        `/${defaultLocale}${path}${window.location.search}`,
      );
    }
  }

  public render() {
    const {
      children,
      initialLocale,
      path,
      setBodyDir,
      translations,
    } = this.props;
    if (!initialLocale && !isSupportedLocaleInPath(path)) {
      return null;
    }
    return (
      <TargemProvider
        detectLocale={false}
        defaultLocale={initialLocale}
        translations={translations}
        setBodyDir={setBodyDir}>
        {children}
      </TargemProvider>
    );
  }
}

export const LocaleProvider = withPageData(LocaleProviderBase);
