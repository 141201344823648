import { graphql, StaticQuery } from "gatsby";
import React from "react";

import { PageDataProvider } from "@src/contexts/PageDataContext";
import MainLayout, { MainLayoutProps } from "@src/layouts/MainLayout";
import RootLayout from "@src/layouts/RootLayout";
import { HeaderStyle, PageProps } from "@src/types";
import { getLocaleFromPath } from "@src/utils/locale";

import CanonicalLink from "./CanonicalLink";
import Head from "./Head";

export type StaticQueryProps = {
  site: {
    siteMetadata: {
      siteUrl: string;
      canonicalHost: string;
    };
  };
};

export type IndexLayoutProps = PageProps &
  Pick<MainLayoutProps, "headerComponent"> & {
    headerStyle?: HeaderStyle;
  };

class IndexLayout extends React.PureComponent<IndexLayoutProps> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutIndexQuery {
            site {
              siteMetadata {
                siteUrl
                canonicalHost
              }
            }
          }
        `}
        render={this.renderStaticQuery}
      />
    );
  }

  private renderStaticQuery = (queryProps: StaticQueryProps) => {
    const {
      children,
      data,
      pageContext,
      headerStyle = "dark",
      headerComponent,
      location,
    } = this.props;
    const { siteUrl, canonicalHost } = queryProps.site.siteMetadata;
    const path = location.pathname.replace(/(\.html)?(\/)?$/i, "");
    const {
      initialLocale = getLocaleFromPath(path)!,
      translations = {},
      blogPostsCount = 1,
    } = pageContext;

    return (
      <PageDataProvider
        value={{
          pageData: data,
          headerStyle,
          path,
          initialLocale,
          blogPostsCount,
          translations,
        }}>
        <CanonicalLink host={canonicalHost} path={path} />
        <RootLayout>
          <Head initialLocale={initialLocale} siteUrl={siteUrl} />
          <MainLayout headerComponent={headerComponent}>{children}</MainLayout>
        </RootLayout>
      </PageDataProvider>
    );
  };
}
export default IndexLayout;
