import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";

import AppLink from "@src/components/AppLink";

import { styles } from "./LinkItem.styles";

export type LinkItemProps = {
  className?: string;
  to: string;
};

class LinkItem extends React.PureComponent<
  WithStyles<typeof styles> & LinkItemProps
> {
  public render() {
    const { classes, className, children, to } = this.props;
    return (
      <Container className={cn(classes.container, className)}>
        <Typography variant="caption">
          <AppLink to={to}>{children}</AppLink>
        </Typography>
      </Container>
    );
  }
}

export default withStyles(styles)(LinkItem);
