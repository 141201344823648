import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import { graphql, StaticQuery } from "gatsby";
import GatsbyImage, { GatsbyImageProps } from "gatsby-image";
import React from "react";

import LocalizedLink from "@src/components/LocalizedLink";

import { styles } from "./IsoLogo.styles";

export type IsoLogoProps = WithStyles<typeof styles> & {
  className?: string;
};

export type StaticQueryProps = {
  iso: { childImageSharp: GatsbyImageProps };
};

class IsoLogo extends React.PureComponent<IsoLogoProps> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query IsoLogoQuery {
            iso: file(relativePath: { eq: "images/iso.png" }) {
              ...iso
            }
          }
        `}
        render={this.renderStaticQuery}
      />
    );
  }

  private renderStaticQuery = (queryProps: StaticQueryProps) => {
    const { classes, className } = this.props;

    return (
      <LocalizedLink
        to="/quality-policy"
        className={cn(className, classes.link)}>
        <GatsbyImage
          fluid={queryProps.iso.childImageSharp.fluid}
          className={classes.logo}
        />
      </LocalizedLink>
    );
  };
}

export default withStyles(styles)(IsoLogo);
