import React from "react";
import { T } from "react-targem";

import { SignInButton, SignInButtonProps } from "./SignInButton";

export type EnterButtonProps = SignInButtonProps;

export class EnterButton extends React.PureComponent<EnterButtonProps> {
  public render() {
    return (
      <SignInButton {...this.props}>
        <T message="Enter" />
      </SignInButton>
    );
  }
}
