import { LocaleCode, LOCALE_CODES } from "@src/config/locales";

export const getDateFnsLocale = (locale: string) => {
  return locale.replace("-", "");
};

export class LocaleNotSupportedError extends Error {}

export const changeLocaleInPath = <L extends string = LocaleCode>(
  path: string,
  newLocale: L,
): string => {
  return path.replace(/^\/(.+?)(\/|$|(\/?\?(.*)$))/g, `/${newLocale}$2`);
};

export const getLocaleFromPath = (
  path: string,
  supportedLocales = LOCALE_CODES,
): string | undefined => {
  const match = path.match(
    new RegExp(`^\/(${supportedLocales.join("|")})(\/|$)`),
  );
  if (!match) {
    return;
  }
  return match[1];
};

export const isSupportedLocaleInPath = (
  path: string,
  supportedLocales = LOCALE_CODES,
): boolean => {
  return !!getLocaleFromPath(path, supportedLocales);
};

export const contactPhone: { [l in LocaleCode]: string } = {
  en: "+33 1 86 96 99 99",
  fr: "+33 1 86 96 99 99",
  ro: "+40 31 229 6819",
  es: "+34 911 196 357",
  it: "+33 1 86 96 99 99",
  he: "+972 77 970 90 90",
};
