import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    footer: {},
    navigationWrapper: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginBottom: theme.spacing(3),
      },
    },
    navigationDesktop: {
      flexGrow: 1,
    },
    logosContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      maxWidth: "204px",
      flexGrow: 1,
    },
    logo: {
      // Aligns footer logo to the column titles
      marginTop: theme.spacing(0.75),
    },
    divider: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });
