import { LOCALE_CODES, LOCALE_TO_DATE_FNS } from "./locales";

export const DATE_FNS_LOCALES: {
  [key: string]: Locale;
} = LOCALE_CODES.reduce((res: { [key: string]: Locale }, locale) => {
  const dateFsnLocale = LOCALE_TO_DATE_FNS[locale] || locale;
  // tslint:disable-next-line: non-literal-require no-unsafe-any
  const importedLocale = require(`date-fns/locale/${dateFsnLocale}`);
  // tslint:disable-next-line: no-unsafe-any
  res[locale] = importedLocale.default || importedLocale;
  return res;
}, {});
