import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: `${theme.spacing(4)}px 0`,
      justifyContent: "space-between",
      alignItems: "center",
    },
    rights: {
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
    terms: {
      "& > :first-child": {
        "&:after": {
          content: `""`,
          margin: `0 ${theme.spacing(2)}px`,
          width: "4px",
          height: "4px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
          display: "inline-block",
          verticalAlign: "middle",
          marginTop: "-2px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });
