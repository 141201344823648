import {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import cn from "classnames";
import React from "react";

import { styles } from "./ExpansionItem.styles";

export type ExpansionItemProps = {
  className?: string;
  title: React.ReactNode;
};

class ExpansionItem extends React.PureComponent<
  WithStyles<typeof styles> & ExpansionItemProps
> {
  public render() {
    const { classes, className, children, title } = this.props;
    return (
      <Container className={cn(classes.container, className)}>
        <ExpansionPanel className={classes.panel}>
          <ExpansionPanelSummary
            className={classes.panelSummary}
            classes={{ content: classes.panelContent }}
            expandIcon={<ArrowDropDown className={classes.expandIcon} />}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelDetails}>
            {React.Children.map(children, (child) => (
              <Typography className={classes.link} variant="body1">
                {child}
              </Typography>
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Container>
    );
  }
}

export default withStyles(styles)(ExpansionItem);
