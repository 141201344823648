import { createMuiTheme, lighten } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import { HeaderStyle } from "@src/types";

import { COLORS } from "./constants";

const breakpoints = createBreakpoints({
  values: {
    xl: 1920,
    lg: 1200,
    md: 960,
    sm: 600,
    xs: 0,
  },
});

export const createTheme = ({
  direction,
  headerStyle,
}: {
  direction: "ltr" | "rtl";
  headerStyle: HeaderStyle;
}) =>
  createMuiTheme({
    trucknet: {
      headerStyle,
    },
    breakpoints,
    direction,
    palette: {
      primary: {
        main: COLORS.primaryMain,
      },
      secondary: {
        main: COLORS.secondaryMain,
        light: COLORS.secondaryLight,
      },
      text: {
        primary: COLORS.textPrimary,
        secondary: COLORS.textSecondary,
      },
      background: {
        default: COLORS.white,
      },
      trucknet: {
        firstGradient: COLORS.firstGradient,
        blue: COLORS.blue,
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: "6rem",
          letterSpacing: "normal",
          lineHeight: 1.17,
          color: COLORS.primaryMain,
          [breakpoints.down("xs")]: {
            fontSize: "3.75rem",
            lineHeight: 1.27,
            fontWeight: 300,
          },
        },
        h2: {
          fontSize: "3.75rem",
          letterSpacing: "normal",
          lineHeight: 1.27,
          fontWeight: 300,
          color: COLORS.primaryMain,
          [breakpoints.down("xs")]: {
            fontSize: "3rem",
            lineHeight: 1.21,
            fontWeight: "normal",
          },
        },
        h3: {
          fontSize: "3rem",
          letterSpacing: "normal",
          lineHeight: 1.21,
          color: COLORS.primaryMain,
          [breakpoints.down("xs")]: {
            fontSize: "2.125rem",
            letterSpacing: "0.25px",
            lineHeight: 1.18,
            fontWeight: 500,
          },
        },
        h4: {
          fontSize: "2.125rem",
          letterSpacing: "0.25px",
          lineHeight: 1.18,
          fontWeight: 500,
          color: COLORS.primaryMain,
        },
        h5: {
          fontSize: "1.5rem",
          letterSpacing: "normal",
          lineHeight: 1.33,
          color: COLORS.primaryMain,
          [breakpoints.down("xs")]: {
            fontSize: "1rem",
            lineHeight: 1.5,
          },
        },
        h6: {
          fontSize: "1.25rem",
          letterSpacing: "normal",
          lineHeight: 1.4,
          fontWeight: 500,
          color: COLORS.primaryMain,
          [breakpoints.down("xs")]: {
            fontSize: "0.875rem",
            lineHeight: 1.71,
          },
        },
        subtitle1: {
          fontSize: "1rem",
          letterSpacing: "normal",
          lineHeight: 1.5,
          fontWeight: 400,
          color: COLORS.primaryMain,
        },
        body1: {
          lineHeight: 1.75,
          color: COLORS.textPrimary,
          letterSpacing: "normal",
          [breakpoints.down("xs")]: {
            fontSize: "0.875rem",
            lineHeight: 1.71,
          },
        },
        body2: {
          lineHeight: 1.71,
          color: COLORS.textPrimary,
        },
        caption: {
          color: COLORS.textSecondary,
          lineHeight: 1.33,
        },
      },
      MuiLink: {
        root: {
          color: COLORS.textPrimary,
          textDecoration: "none",
          "&:hover": {
            color: COLORS.textSecondary,
          },
          "& > *": {
            "&:hover": {
              color: COLORS.textSecondary,
            },
            "&$active,&.active": {
              color: COLORS.secondaryMain,
              "&:hover": {
                color: lighten(COLORS.secondaryMain, 0.3),
              },
            },
          },
        },
      },
      MuiButton: {
        contained: {
          color: COLORS.primaryMain,
        },
        containedSecondary: {
          color: COLORS.white,
          backgroundImage: `linear-gradient(101deg, ${COLORS.firstGradient}, ${COLORS.secondaryMain})`,
          "& $label": {
            color: COLORS.white,
          },
          "&$disabled": {
            backgroundImage: "none",
          },
        },
        sizeLarge: {
          height: "56px",
          fontSize: "20px",
          textTransform: "none",
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
      MuiCardContent: {
        root: {
          padding: "24px",
        },
      },
      MuiContainer: {
        root: {
          [breakpoints.up("md")]: {
            paddingRight: "40px",
            paddingLeft: "40px",
          },
          [breakpoints.down("sm")]: {
            paddingRight: "32px",
            paddingLeft: "32px",
          },
          [breakpoints.down("xs")]: {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
        },
      },
      MuiChip: {
        colorSecondary: {
          color: COLORS.secondaryMain,
          backgroundColor: COLORS.secondaryLight,
        },
      },
    },
  });
